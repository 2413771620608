<template>
  <div v-show="Permissionslist.filter(item=>item==='dashboard.list').length>0" id="OrmDashboard" class="allbox">
    <q-card flat bordered class="my-card duicheng">
      <q-card-section  class='datapick'>
        <span class="monthtitle">订单数量日统计图</span>
        <q-space />
        <div class="block">
          <el-date-picker
            value-format='yyyy-MM-dd'
            v-model="dayvalue2"
            type="daterange"
            align="right"
            unlink-panels
            @change='daybian'
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </q-card-section>
      <!-- height: 21.875rem; -->
      <q-card-section class='Chartbox'>
        <q-card v-if="dayjudge" class="my-card cardbox" style='height:  21.875rem;width: 100%;'>
          <div id="daymain" class='daybox'></div>
        </q-card>
        <q-card v-else class="my-card tiedbox" style='height:  21.875rem;width: 100%;'>
          <span style='line-height: 21.875rem;'>暂无数据</span>
        </q-card>
      </q-card-section>
      <!-- <q-card class="my-card cardbox" style='height: 21.875rem;width: 100%;'>
          <div id="daymain2" class='daybox'></div>
        </q-card> -->
      <!--  -->
      <q-inner-loading :showing="dayvisible">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
    <!-- margin-top: 20px; -->
    <q-card flat bordered class="my-card duicheng" style='margin-top: 20px;min-height: 40rem;'>
      <q-card-section  class='datapick'>
        <!-- <span class="monthtitle">订单数量月统计图</span> -->
        <div class='blocktype'>
          <el-select v-model="monthvalue" clearable placeholder="图表类型" @change='typechange' @clear='typeclear'>
            <el-option
              v-for="item in dashBoardType"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>  
        </div>
        <q-space />
        <div class="block">
          <el-date-picker
            value-format='yyyy-MM-dd'
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            @change='bian'
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </q-card-section>
      <q-card-section class='Chartbox' style='min-height: 40rem;'>
        <q-card v-if="monthjudge" class="my-card cardbox" style='height: 40rem;width: 100%;'>
          <div id="mains" class='monthbox'></div>
        </q-card>
        <q-card v-else class="my-card tiedbox" style='height: 21.875rem;width: 100%;'>
          <span style='line-height: 21.875rem;'>暂无数据</span>
        </q-card>
      </q-card-section>
      <!-- <q-card class="my-card cardbox" style='height: 21.875rem;width: 100%;'>
          <div id="main2" class='daybox'></div>
        </q-card> -->
      <q-inner-loading :showing="monthvisible">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </div>
</template>

<script>
// Permissionslist.filter(item=>item==='dashboard.list').length==0
import { mapState } from "vuex"
import { dayOderData, monthOderData} from "@/api/OrmDashboard.js"
export default {
  created(){
    // console.log("$store", this.$store)
    // console.log("Permissionslist", this.$store.state.Permissionslist)
    // console.log("Permissionslist", this.Permissionslist)
    this.getdaydata()
    this.getmonthdata()
  },
  mounted() {
    this.resizefun = ()=>{
      this.$echarts.init(document.getElementById('daymain')).resize()
      this.$echarts.init(document.getElementById('mains')).resize()
    }
    window.addEventListener('resize',this.resizefun)
  },
  //移除事件监听，避免内存泄漏
  beforeDestroy() {
    window.removeEventListener('resize', this.resizefun)
    this.resizefun = null
  },
  data() {
    return {
      monthvalue:'', //月份图标类型选择
      //时间区间选择
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      value2: '', // 月统计时间
      dayvalue2:'', // 日统计时间

      dayjudge: true, // 控制统计图是否出现
      dayvisible: false, //控制日统计loading
      daybeginDate:'',  //日统计开始时间
      dayendDate:'', // 日统计结束时间
      
      monthjudge: true, //控制月统计图出现
      monthvisible: false, //控制月统计loading
      monthbeginDate:'',  //日统计开始时间
      monthdayendDate:'', // 日统计结束时间
      // 月统计柱状图数据
      option1: {
          tooltip: {
              trigger: 'axis',
              axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: {
            type: 'category',
            data: [],
            axisLabel: { interval: 0, rotate: 70 },
            axisTick: {
                alignWithLabel: true
            }
          }
          ,
          yAxis: [
              {
                type: 'value'
              }
          ],
          series: {
            name:'订单数量',
            data: [],
            type: 'bar',
            showBackground: true,
            barWidth: '60%',
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
          }
      },
      // 日统计折线图数据
      dayoption1: {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        xAxis: {
            type: 'category',
            data: []
        },
        grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
        yAxis: {
            type: 'value'
        },
        series: {
            name:'订单数量',
            data: [],
            type: 'line',
            smooth: true
        }
      },
    };
  },
  components:{},
  computed:{
    ...mapState([
      // 'Permissionslist', // 权限词典
      'dashBoardType', //图标类型 
    ]),
    Permissionslist() {
      return this.$store.state.Permissionslist
    }
  },
  watch:{
    Permissionslist(v, o){
      console.log("Permissionslist 发生变化", v, o)
      console.log(o);
      console.log(v);
      if (v.length != 0) {
        this.getdaydata()
        this.getmonthdata()
      }
      // return this.Permissionslist
    }
  },
  methods: {
    beautySub: function (str, len) {
      var reg = /[\u4e00-\u9fa5]/g,    //专业匹配中文
      slice = str.substring(0, len),
      chineseCharNum = (~~(slice.match(reg) && slice.match(reg).length)),
      realen = slice.length * 2 - chineseCharNum;
      return str.substr(0, realen) + (realen < str.length ? ".." : "");
    },
    formatter(value) {
      if(this.option1.xAxis.data.length>6){
        var str = value.split("")
        return str.join("\n")
			}else{
			  return value;   //必须加上这个，不然栏目小于6个的 不显示标注
			}
    },
    jiancha(){
      console.log('1111');
      console.log(this.Permissionslist);
    },
    // Permissionslist.filter(item=>item==='dashboard.list').length>0
    // 获取日统计数据 你这是不是多折线图?不是是是柱状图,就显示这个月统计的事吧1
    async getdaydata(){
      if(this.Permissionslist.filter(item=>item==='dashboard.list').length>0){
        const { data: res } = await dayOderData({
          beginDate: this.daybeginDate,
          endDate: this.dayendDate,
        })
        console.log(res);
        if(res.code===20000){
          this.dayoption1.xAxis.data = res.data.summary.map((item) =>
            item.key
          )
          this.dayoption1.series.data = res.data.summary.map((item) =>
            item.count
          )
          console.log(this.dayoption1.xAxis.data.length);
          if(this.dayoption1.xAxis.data.length==0){
            this.dayjudge = false
          }else{
            this.dayjudge = true
            this.dayinitecharts()
          }
        }
      }
    },
    // 获取月统计数据
    async getmonthdata(monthrow){
      if(this.Permissionslist.filter(item=>item==='dashboard.list').length>0){
        const { data: res } = await monthOderData({
          beginDate: this.monthbeginDate,
          endDate: this.monthdayendDate,
          type: monthrow
        })
        console.log(res);
        if(res.code===20000){
          this.option1.xAxis.data = res.data.summary.map((item) =>
            item.key
            // this.beautySub(item.key,5)
          )
          this.option1.series.data = res.data.summary.map((item) =>
              item.count
          )
          console.log(this.option1.xAxis.data.length);
          if(this.option1.xAxis.data.length==0){
            this.monthjudge = false
          }else{
            this.monthjudge = true
            this.initecharts()
          }
          // this.brokenlinecharts()
        }
        console.log(this.option1.xAxis.data);
      }
    },
    // 月统计柱状图
    initecharts(){
      if(this.monthjudge){
        this.monthvisible = true
        setTimeout(() =>{
          var chartDom = document.getElementById('mains');
          var myChart = this.$echarts.init(chartDom);
          this.option1 && myChart.setOption(this.option1);
          this.monthvisible = false
        },500)
      }
    },
    //图表类型发生改变时
    typechange(){
      this.getmonthdata(this.monthvalue)
      console.log(this.monthvalue);
    },
    //图标清除时
    typeclear(){
      this.getmonthdata(this.monthvalue)
      console.log(this.monthvalue);
    },
    // 日统计折现图
    dayinitecharts(){
      if(this.dayjudge){
        this.dayvisible = true
        setTimeout(()=>{
          var chartDom = document.getElementById('daymain');
          var myChart = this.$echarts.init(chartDom);
          this.dayoption1 && myChart.setOption(this.dayoption1);
          this.dayvisible = false
        },1000)
      }
    },
    // 月统计图时间改变时触发
    bian(){
      console.log(this.value2);
      this.monthbeginDate= ''
      this.monthdayendDate= ''
      if(this.value2){
        this.monthbeginDate= this.value2[0]
        this.monthdayendDate= this.value2[1]
      }
      this.getmonthdata(this.monthvalue)
    },
    // 日统计图时间改变时触发
    daybian(){
      console.log(this.value2);
      this.daybeginDate= ''
      this.dayendDate= ''
      if(this.dayvalue2){
        this.daybeginDate= this.dayvalue2[0]
        this.dayendDate= this.dayvalue2[1]
      }
      this.getdaydata()
    },
    clear(){
      console.log('1');
    }
  },
};
</script>

<style lang='less' scoped>
// .allbox{
//   display: flex;
//   .duicheng{
//     width: 90%;
//     margin: 0 .9375rem;
//   }
// }
.block{
  margin-right: 1.25rem;
}
.blocktype{
  margin-left: 1.25rem;
}
/deep/.el-date-editor .el-range-separator{
  width: 10%;
}
.daybox, .monthbox{
  display:flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}
.datapick{
  display:flex;
  justify-content: flex-end;
  .monthtitle{
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 2.5rem;
    margin: 0 1.25rem;
  }
}
.cardbox{
  margin: 0 1.25rem;
}
.tiedbox{
  display:flex;
  justify-content: center;
  align-self: center;
  margin: 0 1.25rem;
}
// }
.Chartbox{
  display: flex;
}

</style>
